.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

.nav-icon {
  /* animation: infinite 20s linear; */
  height: 4vmin;
  pointer-events: none;
  /* border: none; */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
body.modal-open {
  padding-right: 0 !important
}
/* body { padding-right: 0 !important } */

/* .custom-toggler.navbar-toggler {
    border-color: rgb(255,102,203);
}
.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
} */

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

/* @import url("./fonts/Barlow/Barlow-Light.ttf"); */

@font-face {
    font-family: 'Barlow';
    font-weight: 900;
    src: local('Barlow'), url(./fonts/Barlow/Barlow-Medium.ttf) format('opentype');
}

/* @import url('https://fonts.googleapis.com/css?family=Ropa+Sans&display=swap'); */
/* @import url('https://fonts.googleapis.com/css?family=Barlow:400,700&display=swap'); */